<template>
  <div class="approvalAgent-outer">
    <el-alert
      title="👨‍💼 设置您的审批代理人，让其在指定时间段内为您处理所有审批任务"
      type="warning"
      :closable="false"
    />
    <div class="form-outer">
      <el-form :model="agent" style="margin-top: 20px" label-width="70px">
        <el-form-item label="代理时限" prop="timeRange">
          <div class="time-outer">
            <el-date-picker
              v-model="agent.timeRange"
              type="datetimerange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
            />
          </div>
        </el-form-item>
        <el-form-item label="代理人" prop="user">
          <el-row>
            <el-col :span="10">
              <el-button
                size="mini"
                icon="el-icon-user"
                @click="$refs.orgPicker.show()"
                >设置代理人</el-button
              >
            </el-col>
            <el-col :span="10">
              <avatar
                :size="35"
                :name="agent.user.name"
                :src="agent.user.avatar"
                v-if="agent.user"
              ></avatar>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- -->
        <el-form-item label="取消代理" v-if="agent.effective">
          <el-button
            size="mini"
            type="warning"
            icon="el-icon-refresh-left"
            @click="cancelAgent"
            >取消代理人</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="time-outer">
      <org-picker
        title="请选择要切换的用户"
        type="user"
        width="200px"
        ref="orgPicker"
        :selected="agent.user ? [agent.user] : []"
        @ok="selected"
      />
    </div>
  </div>
</template>

<script>
import { getUserAgent, setUserAgent, cancelUserAgent } from "@/api/org";
import OrgPicker from "@/components/common/OrgPicker";
import { Dialog } from "vant";

export default {
  name: "ApprovalAgent",
  components: { OrgPicker, Dialog },
  data() {
    return {
      agent: {
        timeRange: null,
        user: null,
        effective: false,
      },
    };
  },
  computed: {
    loginUser() {
      return this.$store.state.loginUser;
    },
    user() {
      return this.$store.state.loginUser;
    },
  },
  mounted() {
    this.getUserAgent();
  },
  methods: {
    selected(users) {
      this.agent.user = users[0];
    },
    getUserAgent() {
      getUserAgent()
        .then((rsp) => {
          if (rsp.data) {
            this.agent = rsp.data;
          } else {
            this.agent.timeRange = null;
            this.agent.user = null;
            this.agent.effective = false;
          }
          if (this.agent.timeRange && this.agent.user) {
            this.agent.effective = true;
          }
        })
        .catch((err) => {
          this.$err(err, "获取用户审批代理人失败");
        });
    },
    setUserAgent(call) {
      if (this.agent.timeRange && this.agent.user) {
        if (this.agent.user.id === this.loginUser.id) {
          this.$message.warning("不允许设置本人为代理人");
          return;
        }
        setUserAgent(this.agent)
          .then((rsp) => {
            this.$ok(rsp, "设置审批代理人成功");
            call();
            this.getUserAgent();
          })
          .cache((err) => {
            this.$err(err, "设置用户审批代理人失败");
          });
      } else {
        this.$message.warning("请完善设置信息");
      }
    },
    cancelAgent() {
      Dialog.confirm({
        title: "提示",
        confirmButtonColor: "#006c50",
        message:
          "取消审批代理人设置后所有的审批任务都将回归到您来处理，确定要取消?",
      })
        .then(() => {
          // on confirm
          cancelUserAgent()
            .then((rsp) => {
              this.$ok(rsp, "取消审批代理人设置成功");
              this.getUserAgent();
            })
            .cache((err) => {
              this.$err(err, "取消审批代理人设置失败");
            });
        })
        .catch(() => {
          // on cancel
        });
      // this.$confirm(
      //   "取消审批代理人设置后所有的审批任务都将回归到您来处理，确定要取消?",
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }
      // ).then(() => {
      //   cancelUserAgent()
      //     .then((rsp) => {
      //       this.$ok(rsp, "取消审批代理人设置成功");
      //       this.getUserAgent();
      //     })
      //     .cache((err) => {
      //       this.$err(err, "取消审批代理人设置失败");
      //     });
      // });
    },
  },
};
</script>

<style >
</style>
<style  lang="less" >
.el-date-range-picker {
  width: 100vw !important;

  top: -10px !important;
  height: 100vh;
  overflow: scroll;
}
.approvalAgent-outer {
  position: relative;
  width: 100vw;
}
.form-outer {
  padding: 1rem;
}

.time-outer {
  font-size: 1.3rem;
  .dialog-txt {
    margin: 1rem 0 2rem;
  }

  /deep/ .el-date-editor {
    width: 90%;
  }
  /deep/ .el-range-input {
    font-size: 1.3rem;
  }
  .el-input__inner {
    width: 290px !important;
  }
  .el-dialog {
    width: 100px;
  }
  /deep/ .el-range-separator {
    width: 10%;
  }
}
</style>
